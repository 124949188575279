<!--
 * @Author       : JiangChao
 * @Date         : 2024-10-29 14:54:58
 * @LastEditors  : JiangChao
 * @LastEditTime : 2024-11-07 17:31:09
 * @Description  : 运单详情页面
-->

<template>
  <div class="container">
    <div class="top-box">
      <div class="left-box">
        <div class="left-top-box">
          <div
            v-if="shipmentInfo.alarmInd === 0"
            class="left-status-box"
            style="background-color: #20bb4c"
          >
            <!-- 是否有告警 0:无告警 1：有告警 -->
            <img :src="icShipmentZan" alt="" />
            <span> {{ $t("no_alert") }}</span>
          </div>

          <div
            v-if="shipmentInfo.alarmInd === 1"
            class="left-status-box"
            style="background-color: #f5582e; flex-direction: row"
          >
            <img :src="icShipmentAlert" alt="" />
            <div class="alert-text">
              <div class="text font-heavy">{{ shipmentInfo.alarmNum }}</div>
              <div class="text font-heavy">{{ $t("alert") }}</div>
            </div>
          </div>

          <div class="left-info-box">
            <div class="info-item">
              <span class="font-heavy" style="font-size: 24px">{{
                shipmentInfo.name
              }}</span>
              <img
                src="@/assets/ic_edit.png"
                alt=""
                @click="changeLoggerName()"
              />
            </div>
            <div class="info-item">
              <div class="font-heavy">
                {{ $t("teams_with_access") }}:
                <span class="font-regular" v-if="orgList.length > 0">
                  {{ orgList[0].orgName || "-" }} </span
                ><span class="more-style font-heavy" @click="updateOrg()"
                  >More...</span
                >
              </div>
              <img src="@/assets/ic_edit.png" alt="" @click="updateOrg()" />
            </div>
            <div class="info-item">
              <div class="font-heavy">
                {{ $t("shipment_device_count") }}:<span class="font-regular">
                  {{ deviceList.length }}</span
                >
              </div>
            </div>
            <div class="info-item">
              <div class="font-heavy">
                {{ $t("note") }}:
                <span class="font-regular">{{
                  shipmentInfo.remark || "-"
                }}</span>
              </div>
              <img
                src="@/assets/ic_edit.png"
                alt=""
                @click="changeLoggerName()"
              />
            </div>
          </div>
        </div>

        <div class="left-bottom-box">
          <div class="operation-stop-btn" @click="shipmentEnd()">
            <img src="@/assets/ic_stop_white.png" />
            <span>{{ $t("stop") }}</span>
          </div>
          <div>
            <el-button type="primary" @click="downloadFile('pdf')">
              {{ $t("export_pdf_report") }}
            </el-button>
            <el-button type="primary" @click="downloadFile('csv')">{{
              $t("download_csv")
            }}</el-button>
            <el-button type="primary" @click="downloadFile('excel')">{{
              $t("edit_shipment")
            }}</el-button>
          </div>
        </div>
      </div>

      <div class="right-box">
        <div class="table-container">
          <table border="0" cellspacing="0" style="border-collapse: collapse">
            <tr>
              <td class="gray-cell label font-heavy">
                {{ $t("goods") }}
              </td>
              <td
                class="gray-cell"
                style="width: 250px; border-right: none !important"
              >
                <span>{{ shipmentInfo.goodsName || "-" }}</span>
              </td>
            </tr>
            <tr>
              <td class="white-cell label font-heavy">
                {{ $t("shipper") }}
              </td>
              <td
                class="white-cell"
                style="width: 250px; border-right: none !important"
              >
                {{ shipmentInfo.shipper || "-" }}
              </td>
            </tr>

            <tr>
              <td class="gray-cell label font-heavy">
                {{ $t("consignee") }}
              </td>
              <td
                class="gray-cell"
                style="width: 250px; border-right: none !important"
              >
                {{ shipmentInfo.receiver || "-" }}
              </td>
            </tr>
            <tr>
              <td class="white-cell label font-heavy">
                {{ $t("mode") }}
              </td>
              <td
                class="white-cell"
                style="width: 250px; border-right: none !important"
              >
                {{ shipmentInfo.transModel | TransModel }}
              </td>
            </tr>

            <tr>
              <td class="gray-cell label font-heavy">
                {{ $t("upload_interval") }}
              </td>
              <td
                class="gray-cell"
                style="width: 250px; border-right: none !important"
              >
                {{ shipmentInfo.uploadInterval | UploadInterval }}
              </td>
            </tr>

            <tr>
              <td
                class="white-cell label font-heavy"
                style="border-bottom: none !important"
              >
                {{ $t("logger") }}
              </td>
              <td
                class="white-cell"
                style="
                  width: 250px;
                  border-right: none !important;
                  border-bottom: none !important;
                "
              >
                <!-- {{ deviceList.length || "-" }} -->
                <div class="device-list">
                  <span
                    v-for="device in deviceList"
                    :key="device.deviceId"
                    @click="goDeviceDetail(device)"
                    class="device-detail"
                  >
                    {{ formatDeviceName(device) }}<br />
                  </span>
                </div>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>

    <div class="step-box">
      <div class="step-start">
        <span>{{ $t("start") }}</span>
        <div class="green-box">
          <div
            :class="
              this.shipmentInfo.status === 1 ? 'green-point' : 'gray-point'
            "
          ></div>
          <div
            :class="this.shipmentInfo.status === 1 ? 'green-line' : 'gray-line'"
            :style="{ width: `${stepLineWidth}` }"
          ></div>
        </div>
        <div class="font-heavy">{{ shipmentInfo.startTime | daysAgo }}</div>
        <span>{{
          shipmentInfo.startTime
            | formatDateWithTimeZone(setSelectedTimeZone, setSelectedLanguage)
        }}</span>
      </div>
      <div class="step-ongoing">
        <span>{{ $t("tab_on_going") }}</span>
        <div class="green-box">
          <div
            class="green-point"
            :class="shipmentInfo.status === 0 ? 'green-point' : 'gray-point'"
          ></div>
          <div
            :class="shipmentInfo.status === 0 ? 'green-line' : 'gray-line'"
            :style="{ width: `${stepLineWidth}` }"
          ></div>
        </div>
        <div class="font-heavy">
          {{ shipmentInfo.alertNum }} {{ $t("records") }}
        </div>
        <span>{{
          shipmentInfo.startTime
            | formatDateWithTimeZone(setSelectedTimeZone, setSelectedLanguage)
        }}</span>
      </div>
      <div class="step-end">
        <span>{{ $t("end") }}</span>
        <div class="green-box">
          <div
            class="green-point"
            :class="shipmentInfo.status === 9 ? 'green-point' : 'gray-point'"
          ></div>
          <div class="green-line"></div>
        </div>
        <div class="font-heavy">{{ $t("not_end") }}</div>
        <span
          >{{ $t("end_time") }}:{{
            shipmentInfo.endTime
              | formatDateWithTimeZone(setSelectedTimeZone, setSelectedLanguage)
          }}</span
        >
      </div>
    </div>
    <div class="echart-box">
      <span class="font-heavy">{{ $t("measurement_history") }}</span>
      <div class="zoom-style">
        <div class="left">
          <div>{{ $t("zoom") }}</div>
          <div class="tab-box">
            <div
              class="tab-item"
              :class="isActive == 1 ? 'is-active' : ''"
              @click="clickTab(1)"
            >
              {{ $t("1_day") }}
            </div>
            <div
              class="tab-item"
              :class="isActive == 2 ? 'is-active' : ''"
              @click="clickTab(2)"
            >
              {{ $t("1_week") }}
            </div>
            <div
              class="tab-item"
              :class="isActive == 3 ? 'is-active' : ''"
              @click="clickTab(3)"
            >
              {{ $t("1_month") }}
            </div>
            <div
              class="tab-item"
              :class="isActive == 4 ? 'is-active' : ''"
              @click="clickTab(4)"
            >
              {{ $t("1_year") }}
            </div>
            <div
              class="tab-item"
              :class="isActive == 5 ? 'is-active' : ''"
              @click="clickTab(5)"
            >
              {{ $t("all") }}
            </div>

            <div>
              {{ $t("select_chart_height") }}:
              <el-select v-model="zoomOptionsValue" @change="changeZoomOption">
                <el-option
                  v-for="item in zoomOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
          </div>
        </div>
        <div class="right">
          <div class="date-tip">{{ $t("from") }}</div>
          <el-date-picker
            v-model="startDate"
            type="date"
            value-format="yyyy-MM-dd"
            :placeholder="$t('start_date')"
            @change="onChangeStart"
          >
          </el-date-picker>
          <div class="date-tip">{{ $t("to") }}</div>
          <el-date-picker
            v-model="endDate"
            type="date"
            value-format="yyyy-MM-dd"
            :placeholder="$t('end_date')"
            @change="onChangeEnd"
          >
          </el-date-picker>
        </div>
      </div>
      <div class="chart">
        <StackedAreaChart ref="chartRef" class="chart-item"></StackedAreaChart>

        <div
          class="additional-div"
          :style="{ 'margin-right': `calc(100% - ${chartWidth})` }"
        >
          <img src="../../assets/ic_echart_alert.png" alt="" />
          <div v-if="!this.tempConfig && !this.wetnessConfig" class="all-null">
            <span> {{ $t("no_alert_rules") }}</span>
          </div>
          <div v-if="this.tempConfig || this.wetnessConfig" class="not-null">
            <div class="high">High <span> - -</span></div>
            <div class="low">Low <span> - -</span></div>
          </div>
        </div>
      </div>
      <!-- <div class="line-bottom-box">
        <div class="bottom">
          <div v-for="(device, index) in allDevicesLine" :key="index">
            <el-checkbox
              v-model="selectedDevicesLine[device.deviceInfo.deviceId]"
              @change="selectDeviceLine(device.deviceInfo.deviceId)"
            >
              <img
                src="@/assets/ic_blue_mark.png"
                alt=""
                srcset=""
                class="custom-img"
                v-if="selectedDevicesLine[device.deviceInfo.deviceId]"
              />
              <img
                src="@/assets/ic_gray_mark.png"
                alt=""
                srcset=""
                class="custom-img"
                v-if="!selectedDevicesLine[device.deviceInfo.deviceId]"
              />
              <span
                >{{ device.deviceInfo.name }}(#{{
                  device.deviceInfo.deviceId
                }})</span
              >
            </el-checkbox>
          </div>
          <el-checkbox v-model="selectAllLine" @change="selectAllDevicesLine">
            <img
              src="@/assets/ic_blue_mark.png"
              alt=""
              srcset=""
              class="custom-img"
              v-if="selectAllLine"
            />
            <img
              src="@/assets/ic_gray_mark.png"
              alt=""
              srcset=""
              class="custom-img"
              v-if="!selectAllLine"
            />
            <span>{{ $t("all") }}</span>
          </el-checkbox>
        </div>
      </div> -->
      <el-button
        type="primary"
        @click="downloadExcel"
        style="margin-top: 20px"
        >{{ $t("view_as_list_or_download_excel") }}</el-button
      >
    </div>

    <el-col :gutter="24">
      <el-row :span="24">
        <div class="desc3 font-heavy">
          {{ $t("locations_of_loggers") }}
          <!-- ({{ list.length }}) -->
          <div class="function">
            <!-- <img
              @click="getDashboardSensorList"
              src="../../assets/ic_refresh.png"
              alt=""
            /> -->
            <!-- <img src="../../assets/ic_more.png" alt="" /> -->
          </div>
        </div>

        <div id="container" class="map" v-if="isChina"></div>
        <div id="container2" class="map" v-if="!isChina"></div>
        <!-- <div id="container2" class="map"></div> -->
      </el-row>
    </el-col>
    <div class="bottom-box">
      <div class="bottom">
        <div v-for="(item, index) in this.allDevices" :key="index">
          <el-checkbox
            v-model="selectedDevices[item.deviceInfo.deviceId]"
            @change="selectDevice(item.deviceInfo.deviceId)"
          >
            <img
              src="@/assets/ic_blue_mark.png"
              alt=""
              srcset=""
              class="custom-img"
              v-if="selectedDevices[item.deviceInfo.deviceId]"
            />
            <img
              src="@/assets/ic_gray_mark.png"
              alt=""
              srcset=""
              class="custom-img"
              v-if="!selectedDevices[item.deviceInfo.deviceId]"
            />
          </el-checkbox>
          <!-- <img src="@/assets/ic_dangerous.png" alt="" srcset="" /> -->
          <span>
            {{ item.deviceInfo.name }}(#{{ item.deviceInfo.deviceId }})</span
          >
        </div>
        <el-checkbox v-model="selectAll" @change="selectAllDevices">
          <img
            src="@/assets/ic_blue_mark.png"
            alt=""
            srcset=""
            class="custom-img"
            v-if="selectAll"
          />
          <img
            src="@/assets/ic_gray_mark.png"
            alt=""
            srcset=""
            class="custom-img"
            v-if="!selectAll"
          />
        </el-checkbox>
        <span> {{ $t("all") }}</span>
      </div>
    </div>

    <div class="rules-box">
      <div class="rules-title">
        <div class="left">
          <span class="font-heavy"> {{ $t("alert_rules_key") }}</span>
          <!-- （1）没有报警规则，且不可设置，没有设置报警规则 且不允许设置新的报警规则时 -->
          <div v-if="form.detailList.length <= 0 && rulesEnabled == 0">
            <span class="font-light"> {{ $t("no_alert_rules") }}.</span>
            <span class="font-light"> {{ $t("logger_started_tip") }}</span>
          </div>
          <!-- 没有报警规则，可以设置新的 -->
          <div v-if="form.detailList.length <= 0 && rulesEnabled == 1">
            <span class="font-light"> {{ $t("no_alert_rules_tip") }}</span>
          </div>

          <span class="font-light" v-if="form.detailList.length > 0">
            {{ $t("alert_rules_tip_key") }}</span
          >
        </div>
        <div class="right">
          <el-button
            type="primary"
            @click="selectExistedRules"
            :disabled="rulesEnabled == 0 ? true : false"
            :style="
              rulesEnabled == 0 ? ' background: #1a72f4;opacity: 0.8;' : ''
            "
            >{{ $t("select_existed_rules") }}</el-button
          >
          <el-button
            type="primary"
            @click="newRules"
            :disabled="rulesEnabled == 0 ? true : false"
            :style="
              rulesEnabled == 0 ? ' background: #1a72f4;opacity: 0.8;' : ''
            "
            >+{{ $t("new_rules") }}</el-button
          >
        </div>
      </div>

      <el-form ref="form" :model="form" label-width="120px">
        <el-form-item
          v-for="(rule, index) in form.detailList"
          :key="rule.key"
          :prop="'detailList.' + index + '.value'"
        >
          <div class="rule-box">
            <div class="selects-container">
              <el-select v-model="rule.type" class="select-item" disabled>
                <el-option
                  :label="item.name"
                  :value="item.value"
                  v-for="item in alarmType"
                  :key="item.value"
                ></el-option>
              </el-select>
              <el-select v-model="rule.source" class="select-item" disabled>
                <el-option
                  :label="item.name"
                  :value="item.value"
                  v-for="(item, index) in alarmSensor"
                  :key="index"
                ></el-option>
              </el-select>
              <!-- <el-select v-model="rule.conditions" class="select-item" disabled>
                <el-option
                  :label="item.name"
                  :value="item.value"
                  v-for="item in alarmConditions"
                  :key="item.value"
                ></el-option>
              </el-select> -->

              <!-- <el-input
                v-model="rule.value"
                class="input-item"
                disabled
              ></el-input> -->

              <el-input
                class="input-item custom-placeholder"
                placeholder="less than"
                disabled
              >
              </el-input>

              <el-input
                v-model.number="rule.min"
                class="input-item"
                disabled
              ></el-input>
              OR
              <el-input
                class="input-item custom-placeholder"
                placeholder="more than"
                disabled
              >
              </el-input>

              <el-input
                v-model.number="rule.max"
                class="input-item"
                disabled
              ></el-input>
              <el-input
                v-model="rule.unit"
                class="input-item"
                disabled
                v-if="!showTemperatureUnit(rule)"
              >
              </el-input>
              <div v-if="showDurationSelector(rule)">
                For more than
                <el-input
                  v-model.number="rule.duration"
                  disabled
                  class="input-item"
                ></el-input>
                <el-input
                  placeholder="Minutes"
                  class="input-item custom-placeholder"
                  disabled
                  style="margin-left: 8px"
                ></el-input>
              </div>
            </div>
            <img
              v-if="rulesEnabled != 0"
              @click.prevent="removeRule(rule)"
              src="../../assets/ic_cross.png"
              alt=""
            />
          </div>
        </el-form-item>
      </el-form>

      <!-- <div>{{ $t("alert_rules_tip") }}</div> -->
    </div>

    <div class="alert-box">
      <div class="alert-title">
        <span class="font-heavy">{{ $t("alert") }}</span>
        <el-button type="primary" @click="setRead">{{
          $t("mark_selected_alerts_as_checked")
        }}</el-button>
      </div>
      <!-- <div class="table-title">{{ $t("showing") }}</div> -->
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane :label="$t('unchecked')" name="0"></el-tab-pane>
        <el-tab-pane :label="$t('checked')" name="1"></el-tab-pane
      ></el-tabs>
      <el-table
        :data="alarmList"
        style="width: 100%"
        stripe
        border
        :header-cell-style="{
          background: '#f6f6fa',
          textAlign: 'center',
          color: 'black',
        }"
        ref="alarmTable"
        :cell-style="{ 'text-align': 'center' }"
        @selection-change="handleAlarmSelectionChange"
        :empty-text="$t('no_data')"
      >
        <el-table-column prop="content" :label="$t('cause')">
          <template v-slot:default="scope">
            <div class="cause-box">
              <div class="source-box">
                <span v-if="scope.row.source === 'D_Temp'"
                  >{{ $t("tempture") }}{{ $t("alert_status") }}</span
                >
                <span v-if="scope.row.source === 'D_Rh'">
                  {{ $t("humidity") }}{{ $t("alert_status") }}</span
                >
                <span v-if="scope.row.source === 'D_SensorBattery'">
                  {{ $t("battery") }}{{ $t("alert_status") }}</span
                >
                <span v-if="scope.row.source === 'D_SensorLight'">
                  {{ $t("light_intensity") }}{{ $t("alert_status") }}</span
                >
                <span v-if="scope.row.source === 'D_SensorShock'">
                  {{ $t("vibrate") }}{{ $t("alert_status") }}</span
                >
              </div>
              <div class="rule-box"></div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="createdDate" :label="$t('time')">
          <template v-slot:default="scope">
            <span>
              {{
                scope.row.createdDate
                  | formatDateWithTimeZone(
                    setSelectedTimeZone,
                    setSelectedLanguage
                  )
              }}</span
            >
          </template>
        </el-table-column>
        >
        <el-table-column prop="content" :label="$t('logger')">
          <template v-slot:default="scope">
            <span class="logger-detail">
              {{ scope.row.deviceName }}(#{{ scope.row.deviceId }})</span
            >
          </template>
        </el-table-column>
        <el-table-column prop="comment" :label="$t('comments')">
          <template v-slot:default="scope">
            <div class="comment-box" v-if="!scope.row.comment">
              <el-button
                type="primary"
                class="add-comment"
                @click="addComment(scope.row)"
                style="
                  background: transparent;
                  border: 1px solid transparent;
                  color: #1a72f4;
                  text-decoration: underline;
                "
              >
                +{{ $t("add_comment") }}</el-button
              >
            </div>
            <div v-else-if="scope.row.comment">{{ scope.row.comment }}</div>
          </template>
        </el-table-column>
        <el-table-column type="selection" width="55"> </el-table-column>
      </el-table>
      <el-pagination
        style="margin-top: 10px; text-align: center"
        background
        @current-change="handleCurrentChange"
        :current-page="Number(this.pageNum)"
        :page-sizes="[10, 20, 30, 40]"
        :page-size="Number(this.pageSize)"
        layout="total,pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>

    <div class="statistics-box">
      <span class="font-heavy" v-if="setSelectedLanguage === 'zh'">
        {{ $t("loggers_for_this_shipment") }}{{ deviceInfoList.length }}
        {{ $t("number_of_devices") }}
      </span>
      <span class="font-heavy" v-if="setSelectedLanguage === 'en'"
        >{{ deviceInfoList.length }} {{ $t("loggers_for_this_shipment") }}</span
      >
      <div class="device-title">
        <span class="font-heavy title">{{ $t("latest_measurements") }}</span>
        <div class="device-list-style">
          <LoggerCard
            v-for="(item, index) in deviceInfoList"
            :key="index"
            :item="item"
            class="device-card"
            @editDeviceName="changeLoggerName"
          ></LoggerCard>
        </div>
      </div>
    </div>

    <div class="summary-box">
      <span class="font-heavy">{{ $t("data_summary") }}</span>
      <el-table
        :data="totalList"
        style="width: 100%"
        border
        :header-cell-style="{
          background: '#f6f6fa',
          textAlign: 'center',
          color: 'black',
        }"
        :cell-style="{ 'text-align': 'center' }"
        :empty-text="$t('no_data')"
        :span-method="objectSpanMethod"
      >
        <!-- Logger 列 -->
        <el-table-column label="Logger">
          <template slot-scope="scope">
            {{ scope.row.deviceInfo.deviceId }} -
            {{ scope.row.deviceInfo.name }}
          </template>
        </el-table-column>

        <!-- 温度最小值 -->
        <el-table-column prop="tempMin" :label="$t('mins')">
          <template slot-scope="scope">
            {{ scope.row.dataTotal.tempMin || "-" }}<br />
            {{ scope.row.dataTotal.wetnessMin || "-" }}<br />
          </template>
        </el-table-column>

        <!-- 温度最大值 -->
        <el-table-column prop="tempMax" :label="$t('maxes')">
          <template slot-scope="scope">
            {{ scope.row.dataTotal.tempMax || "-" }}<br />
            {{ scope.row.dataTotal.wetnessMax || "-" }}<br />
          </template>
        </el-table-column>

        <!-- 温度平均值 -->
        <el-table-column prop="tempAvg" :label="$t('averages')">
          <template slot-scope="scope">
            {{ scope.row.dataTotal.tempAvg || "-" }}<br />
            {{ scope.row.dataTotal.wetnessAvg || "-" }}<br />
          </template>
        </el-table-column>

        <!-- MKT -->
        <el-table-column
          prop="totalList.dataTotal.tempMkt"
          :label="$t('D_Mkt')"
        >
          <template slot-scope="scope">
            {{ scope.row.dataTotal.tempMkt || "-" }}<br />
          </template>
        </el-table-column>

        <!-- 总数 -->
        <el-table-column
          prop="totalList.dataTotal.total"
          :label="$t('number_of_records')"
        >
          <template slot-scope="scope">
            {{ scope.row.dataTotal.total || "-" }}<br />
          </template>
        </el-table-column>

        <!-- 警报总数 -->
        <el-table-column
          prop="totalList.dataTotal.alarmTotal"
          :label="$t('total_number_of_alarms')"
        >
          <template slot-scope="scope">
            {{ scope.row.dataTotal.alarmTotal || "-" }}<br />
          </template>
        </el-table-column>
        <!-- 合并单元格显示 duration -->
        <el-table-column :label="$t('duration')" width="180">
          <template slot-scope="scope">
            <span style="" v-if="scope.$index === 0">{{
              duration || "-"
            }}</span>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- <ShareLoggerDialog
      ref="shareRef"
      @isShare="handleIsShare"
    ></ShareLoggerDialog>
   
    <ChangeLoggerRemarkDialog
      ref="remarkRef"
      @refresh="getDetailInfo"
    ></ChangeLoggerRemarkDialog> -->
    <!-- <ChangeAlarmConditionsDialog ref="editRef" @refresh="getShipmentAlarmConfig">
    </ChangeAlarmConditionsDialog> -->
    <!-- <TransferDeviceDialog ref="transferRef"></TransferDeviceDialog> -->
    <!-- <AddDeviceToSceneDialog ref="deviceToSceneRef"></AddDeviceToSceneDialog> -->
    <!-- <AddDeviceToOrganDialog ref="deviceToOrganRef"></AddDeviceToOrganDialog>
    

    <SelectExistedRulesDialog
      ref="existedRulesRef"
      @refresh="getAlarmRules"
    ></SelectExistedRulesDialog> -->
    <AddCommentDialog
      ref="commentRef"
      @refresh="getShipmentDetailAlarmList"
    ></AddCommentDialog>
    <ChangeLoggerNameDialog
      ref="nameRef"
      @refresh="getShipmentDetailDeviceData"
    ></ChangeLoggerNameDialog>
    <UpdateShipmentOrgDialog
      ref="updateOrgRef"
      @refresh="getShipmentDetailDeviceData"
    ></UpdateShipmentOrgDialog>
  </div>
</template>

<script>
// import ShareLoggerDialog from "../logger/modal/ShareLoggerDialog.vue";
import ChangeLoggerNameDialog from "@/pages/logger/modal/ChangeLoggerNameDialog.vue";
// import ChangeLoggerRemarkDialog from "./modal/ChangeLoggerRemarkDialog.vue";
import StackedAreaChart from "../components/StackedAreaChart.vue";
// import ChangeAlarmConditionsDialog from "./modal/ChangeAlarmConditionsDialog.vue";
// import TransferDeviceDialog from "./modal/TransferDeviceDialog.vue";
// import AddDeviceToSceneDialog from "./modal/AddDeviceToSceneDialog.vue";
// import AddDeviceToOrganDialog from "./modal/AddDeviceToOrganDialog.vue";
import AddCommentDialog from "./modal/AddCommentDialog.vue";
import UpdateShipmentOrgDialog from "./modal/UpdateShipmentOrgDialog.vue";

// import SelectExistedRulesDialog from "./modal/SelectExistedRulesDialog.vue";
import moment from "moment";
import {
  getByShipmentIdApi,
  getShipmentOrgListApi,
  getShipmentDetailAlarmListApi,
  shipmentEndApi,
  getShipmentAlarmConfigApi,
  startRecordingApi,
  discardDeviceApi,
  getShipmentDetailDeviceDataApi,
  // setAlarmReadApi,
  shipmentDetailAlarmCheckedApi,
  // getDetailInfoApi,
  getShipmentDetailLineDataApi,
  // getDetailSummaryApi,
  // getAlarmRulesApi,
  // getAlarmListApi,
  stopRecordingApi,
  removeDetailDeviceApi,
  selectApi,
  deleteAlarmSettingApi,
} from "@/api/api.js";
// import withoutAlertIcon from "@/assets/ic_no_alert.png";
// import withAlertIcon from "@/assets/ic_alert.png";
import locationIcon from "@/assets/ic_location.png";

import _ from "lodash";
import LoggerCard from "../shipment/components/LoggerCard.vue";
/* eslint-disable */

export default {
  name: "ShipmentDetail",
  data() {
    return {
      shipmentId: "",
      shipmentInfo: {},
      deviceList: [],
      icShipmentAlert: require("@/assets/ic_shipment_alert.png"),
      icShipmentZan: require("@/assets/ic_shipment_zan.png"),
      orgList: [],
      zoomOptions: [
        { value: "1", label: "1x" },
        { value: "2", label: "2x" },
        { value: "3", label: "3x" },
      ],
      zoomOptionsValue: "1",
      chartHeight: "500px",
      zoomValue: 40,
      readInd: "0",
      selectedDevices: {}, // 记录哪些设备已被选中
      selectedDevicesLine: {}, // 选中的设备
      selectAll: true,
      selectAllLine: true, // 是否全选
      allDevices: [],
      allDevicesLine: [], // 所有设备
      deviceInfoList: [],
      deviceBaseInfo: {},
      deviceDataInfo: {},
      form: { detailList: {} },
      sceneInfo: { name: "" },
      orgInfo: {},
      option: {
        color: ["#76BFF5", "#50D69C"],
        legend: [],
        xData: [],
        unit: "℃",
        axisLabel: {
          show: true, // 是否显示刻度标签，默认显示
          interval: 0, // 坐标轴刻度标签的显示间隔，在类目轴中有效；
        },
        yAxis: [{ name: "", min: 0, max: 0 }, { name: "" }],
        y2Height: 70,
        y1Name: "",
        x1Name: "",
        lineSeries: [
          // {
          //   name: "",
          //   type: "line",
          //   smooth: true,
          //   areaStyle: {
          //     opacity: 0.1,
          //   },
          //   emphasis: {
          //     focus: "series",
          //   },
          //   itemStyle: {
          //     normal: {
          //       label: {
          //         show: false,
          //         position: "top",
          //       },
          //     },
          //   },
          //   data: [],
          //   markLine: {
          //     data: [],
          //   },
          // },
          // {
          //   name: "",
          //   type: "line",
          //   smooth: true,
          //   areaStyle: {
          //     opacity: 0.1,
          //   },
          //   emphasis: {
          //     focus: "series",
          //   },
          //   itemStyle: {
          //     normal: {
          //       label: {
          //         show: false,
          //         position: "top",
          //       },
          //     },
          //   },
          //   data: [],
          //   markLine: {
          //     data: [],
          //   },
          // },
        ],
      },
      alarmList: [],
      isActive: 1,
      startDate: "",
      endDate: "",
      deviceIds: [],
      alarmSelection: [],
      // lineDataList: [],
      type: 1, //统计类型 1：Day 2:Week 3:Month 4:Year 9:All
      // summaryAlarmTotal: 0,
      // summaryDuration: "",
      // summaryRecordTotal: 0,
      totalList: [],
      isRecord: false,
      isDelete: false,
      isDiscard: false,
      isShare: false,
      alarmType: [],
      alarmSensor: [],
      alarmConditions: [],
      total: 0,
      pageNum: 1,
      pageSize: 10,
      timezone: 0,
      isChina: true,
      limits: "",
      deviceId: "",
      cycleInd: 0, //是否重复使用设备标识 1:是，0:否 0时告警规则不允许编辑
      rulesEnabled: 0,

      startTime: "",
      endTime: "",
      tempConfig: {
        id: null,
        deviceId: "202405000049",
        historyId: "0",
        unit: "C",
        type: 0,
        source: 1,
        max: 25,
        min: 15,
        delay: 0,
        duration: 0,
      },
      wetnessConfig: {
        id: null,
        deviceId: "202405000049",
        historyId: "0",
        unit: "℃",
        type: 0,
        source: 2,
        max: 50,
        min: 10,
        delay: 0,
        duration: 0,
      },
      active: 0,
      currentInfoWindow: null,
      loggerListLine: [
        {
          deviceInfo: {
            deviceId: "202406000058",
            model: "T670",
            name: "T670",
            version: null,
          },
          lineList: [
            {
              gatherDate: "2024-07-01 17:18:11",
              temp: "30.9",
              wetness: "63",
              temp1: null,
              wetness1: null,
              gatherTimestamp: 1719854291,
            },
            {
              gatherDate: "2024-07-01 17:23:11",
              temp: "31.3",
              wetness: "62",
              temp1: null,
              wetness1: null,
              gatherTimestamp: 1719854591,
            },
            {
              gatherDate: "2024-07-01 17:24:11",
              temp: "31.2",
              wetness: "62",
              temp1: null,
              wetness1: null,
              gatherTimestamp: 1719854651,
            },
            {
              gatherDate: "2024-07-01 17:25:11",
              temp: "31.1",
              wetness: "63",
              temp1: null,
              wetness1: null,
              gatherTimestamp: 1719854711,
            },
            {
              gatherDate: "2024-07-01 17:26:11",
              temp: "31.1",
              wetness: "63",
              temp1: null,
              wetness1: null,
              gatherTimestamp: 1719854771,
            },
            {
              gatherDate: "2024-07-01 17:27:11",
              temp: "30.9",
              wetness: "63",
              temp1: null,
              wetness1: null,
              gatherTimestamp: 1719854831,
            },
            {
              gatherDate: "2024-07-01 17:28:00",
              temp: "31.3",
              wetness: "61",
              temp1: null,
              wetness1: null,
              gatherTimestamp: 1719854880,
            },
          ],
        },
        {
          deviceInfo: {
            deviceId: "202405000053",
            model: "T671",
            name: "T670",
            version: null,
          },
          lineList: [
            {
              gatherDate: "2024-07-01 17:18:11",
              temp: "30.9",
              wetness: "63",
              temp1: null,
              wetness1: null,
              gatherTimestamp: 1719854291,
            },
            {
              gatherDate: "2024-07-01 17:19:11",
              temp: "32.0",
              wetness: "62",
              temp1: null,
              wetness1: null,
              gatherTimestamp: 1719854351,
            },
            {
              gatherDate: "2024-07-01 17:20:11",
              temp: "32.0",
              wetness: "60",
              temp1: null,
              wetness1: null,
              gatherTimestamp: 1719854411,
            },
            {
              gatherDate: "2024-07-01 17:21:11",
              temp: "31.6",
              wetness: "61",
              temp1: null,
              wetness1: null,
              gatherTimestamp: 1719854471,
            },
          ],
        },
        {
          deviceInfo: {
            deviceId: "202405000051",
            model: "T670",
            name: "T670",
            version: null,
          },
          lineList: [
            {
              gatherDate: "2024-07-01 17:18:11",
              temp: "30.9",
              wetness: "63",
              temp1: null,
              wetness1: null,
              gatherTimestamp: 1719854291,
            },
            {
              gatherDate: "2024-07-01 17:19:11",
              temp: "32.0",
              wetness: "62",
              temp1: null,
              wetness1: null,
              gatherTimestamp: 1719854351,
            },
            {
              gatherDate: "2024-07-01 17:20:11",
              temp: "32.0",
              wetness: "60",
              temp1: null,
              wetness1: null,
              gatherTimestamp: 1719854411,
            },
            {
              gatherDate: "2024-07-01 17:28:00",
              temp: "31.3",
              wetness: "61",
              temp1: null,
              wetness1: null,
              gatherTimestamp: 1719854880,
            },
          ],
        },
        {
          deviceInfo: {
            deviceId: "202405000049",
            model: "T670",
            name: "T1000",
            version: null,
          },
          lineList: [
            {
              gatherDate: "2024-07-01 17:18:11",
              temp: "30.9",
              wetness: "63",
              temp1: null,
              wetness1: null,
              gatherTimestamp: 1719854291,
            },

            {
              gatherDate: "2024-07-01 17:27:11",
              temp: "31.9",
              wetness: "53",
              temp1: null,
              wetness1: null,
              gatherTimestamp: 1719854831,
            },
            {
              gatherDate: "2024-07-01 17:28:00",
              temp: "32.3",
              wetness: "71",
              temp1: null,
              wetness1: null,
              gatherTimestamp: 1719854880,
            },
          ],
        },
      ], // 当前展示的设备数据
      loggerList: [
        {
          deviceInfo: {
            deviceId: "202406000058",
            model: "T670",
            name: "T670",
            version: null,
          },
          mapList: [
            {
              latitude: "31.23818",
              longitude: "121.48315",
              timestamps: 0,
            },
            {
              latitude: "34.26683",
              longitude: "108.95014",
              timestamps: 0,
            },
            {
              latitude: "28.19217",
              longitude: "112.98132",
              timestamps: 0,
            },
            {
              latitude: "29.56835",
              longitude: "106.58542",
              timestamps: 0,
            },
            {
              latitude: "23.02731",
              longitude: "113.75842",
              timestamps: 0,
            },
          ],
        },
        {
          deviceInfo: {
            deviceId: "202405000053",
            model: "T671",
            name: "T670",
            version: null,
          },
          mapList: [
            {
              latitude: "31.31727",
              longitude: "120.63256",
              timestamps: 0,
            },
          ],
        },
        {
          deviceInfo: {
            deviceId: "202405000051",
            model: "T670",
            name: "T670",
            version: null,
          },
          mapList: [
            {
              latitude: "30.4828",
              longitude: "114.41709",
              timestamps: 0,
            },
            {
              latitude: "36.06808",
              longitude: "120.39135",
              timestamps: 0,
            },
          ],
        },
        {
          deviceInfo: {
            deviceId: "202405000049",
            model: "T670",
            name: "T1000",
            version: null,
          },
          mapList: [
            {
              latitude: "30.66378",
              longitude: "104.08517",
              timestamps: 0,
            },
          ],
        },
      ],
      withAlertNum: 0,
      withoutAlertNum: 0,
      list: [],
      withAlert: true,
      withoutAlert: true,
      activeName: "0",
      tableData: [],
    };
  },
  methods: {
    downloadFile() {
      this.$message.success("正在开发中...");
    },
    shareLogger() {
      this.$refs.shareRef.showDialog(this.deviceId);
    },
    changeLoggerName(id) {
      this.$refs.nameRef.showDialog(id);
    },
    changeLoggerRemark() {
      this.$refs.remarkRef.showDialog(this.deviceId);
    },
    updateOrg() {
      this.$refs.updateOrgRef.showDialog(
        this.shipmentId,
        this.shipmentInfo.userId,
        this.orgList
      );
    },
    startRecording() {
      if (this.cycleInd === 0) {
        return;
      }
      let params = {
        deviceId: this.deviceId,
        timezone: this.timezone,
      };
      startRecordingApi(params).then((r) => {
        // console.log("=====", r);
        this.$message.success(this.$t("start_recording"));
        // this.isRecord = false;
        this.getDetailInfo();
      });
    },

    discardDevice() {
      this.$alert(this.$t("discard_tip"), this.$t("tip"), {
        confirmButtonText: this.$t("confirm"),
        cancelButtonText: this.$t("cancel"),
        showCancelButton: true,
        type: "warning",
      }).then(() => {
        let params = {
          deviceId: this.deviceId,
          timezone: this.timezone,
        };
        discardDeviceApi(params).then((r) => {
          // console.log("=====", r);
          this.$message.success(this.$t("operation_successful"));
          this.isDiscard = !this.isDiscard;
        });
      });
    },

    removeDevice() {
      this.$alert(this.$t("delete_tip"), this.$t("tip"), {
        confirmButtonText: this.$t("confirm"),
        cancelButtonText: this.$t("cancel"),
        showCancelButton: true,
        type: "warning",
      }).then(() => {
        let params = {
          deviceId: this.deviceId,
          timezone: this.timezone,
        };
        removeDetailDeviceApi(params).then((r) => {
          // console.log("=====", r);
          this.$message.success(this.$t("remove_logger_success"));
          this.isDelete = !this.isDelete;
          // this.dialogFormVisible = false;
          this.goPages("/home/board");
        });
      });
    },
    stopRecording() {
      if (this.cycleInd === 0) {
        return;
      }
      let params = {
        deviceId: this.deviceId,
        timezone: this.timezone,
      };
      stopRecordingApi(params).then((r) => {
        // console.log("=====", r);
        this.$message.success(this.$t("stop_record"));
        // this.dialogFormVisible = false;
        // this.isRecord = true;
        this.getDetailInfo();
      });
    },

    clickTab(idx) {
      this.allDevicesLine = [];
      //统计类型 1：Day 2:Week 3:Month 4:Year 9:All
      this.isActive = idx;
      // console.log("clickTab---idx---" + idx);
      switch (idx) {
        case 1:
          this.type = 1;
          // console.log("日", moment().format("YYYY-MM-DD"));
          this.startDate = moment().format("YYYY-MM-DD");
          this.endDate = moment().format("YYYY-MM-DD");
          break;
        case 2:
          this.type = 2;
          // console.log("周", moment().startOf("isoWeek").format("YYYY-MM-DD"));
          this.startDate = moment()
            .startOf("isoWeek")
            .format("YYYY-MM-DD");
          this.endDate = moment()
            .endOf("isoWeek")
            .format("YYYY-MM-DD");
          break;
        case 3:
          this.type = 3;
          // console.log("月", moment().startOf("month").format("YYYY-MM-DD"));
          this.startDate = moment()
            .startOf("month")
            .format("YYYY-MM-DD");
          this.endDate = moment()
            .endOf("month")
            .format("YYYY-MM-DD");
          break;
        case 4:
          this.type = 4;
          // console.log("年", moment().startOf("year").format("YYYY-MM-DD"));
          this.startDate = moment()
            .startOf("year")
            .format("YYYY-MM-DD");
          this.endDate = moment()
            .endOf("year")
            .format("YYYY-MM-DD");
          break;
        case 5:
          this.type = 9;
          this.startDate = "";
          this.endDate = "";
          break;
      }
      this.getShipmentDetailLineData();
    },
    formatDeviceName(device) {
      return `${device.name} - #${device.deviceId}`;
    },
    onChangeStart(val) {
      // console.log("val: ", val);
      this.startDate = val;
      this.getShipmentDetailLineData();
    },

    onChangeEnd(val) {
      // console.log("val: ", val);
      this.endDate = val;
      this.getShipmentDetailLineData();
    },

    transferDevice() {
      this.$refs.transferRef.showDialog(this.deviceId);
    },
    addComment(item) {
      this.$refs.commentRef.showDialog(item);
    },

    // getDetailSummary() {
    //   getDetailSummaryApi({ deviceId: this.deviceId }).then((r) => {
    //     console.log("获取温湿度统计以及summary信息=====", r);
    //     this.totalList = r.data.totalList;
    //     this.summaryAlarmTotal = r.data.summaryAlarmTotal;
    //     this.summaryDuration = r.data.summaryDuration;
    //     this.summaryRecordTotal = r.data.summaryRecordTotal;
    //   });
    // },
    getShipmentDetailDeviceData() {
      let params = {
        shipmentId: this.shipmentId,
        timezone: this.timezone,
        // startDate: this.startDate,
        // endDate: this.endDate,
        // type: this.type, //统计类型 1：Day 2:Week 3:Month 4:Year 9:All
        // startDateTimestamp: 0,
      };
      getShipmentDetailDeviceDataApi(params).then((r) => {
        console.log("getShipmentDetailDeviceDataApi---获取设备=====", r);
        this.loggerListLine = r.data.lineList || [];
        // this.tempConfig = r.data.tempConfig;
        // this.wetnessConfig = r.data.wetnessConfig;
        // console.log("this.lineDataList: ", JSON.stringify(this.lineDataList));
        if (this.loggerListLine.length <= 0) {
          this.$message.success(this.$t("no_data"));
        }

        this.allDevicesLine = this.loggerListLine;
        this.selectAllDevicesLine(); // 默认全选

        this.loggerList = r.data.mapList;
        this.totalList = r.data.totalList;
        this.deviceInfoList = r.data.deviceInfoList;
        this.duration = r.data.duration;

        // 默认选中地图所有设备
        this.loggerList.forEach((device) => {
          this.$set(this.selectedDevices, device.deviceInfo.deviceId, true);
          this.allDevices.push(device);
        });

        this.loadMap();
        this.$nextTick(() => {
          this.$refs.chartRef.init(this.option);
        });
      });
    },
    getShipmentDetailLineData() {
      let params = {
        shipmentId: this.shipmentId,
        timezone: this.timezone,
        startDate: this.startDate,
        endDate: this.endDate,
        type: this.type, //统计类型 1：Day 2:Week 3:Month 4:Year 9:All
      };
      getShipmentDetailLineDataApi(params).then((r) => {
        console.log("获取折线图数据=====", r);
        // console.log("this.lineDataList: ", this.lineDataList);
        this.loggerListLine = r.data || [];
        if (this.lineDataList.length <= 0) {
          this.$message.success(this.$t("no_data"));
        }
        // this.tempConfig = r.data.tempConfig;
        // this.wetnessConfig = r.data.wetnessConfig;
        this.allDevicesLine = this.loggerListLine;
        this.selectAllDevicesLine(); // 默认全选

        // // 默认选中所有设备
        // this.loggerListLine.forEach((device) => {
        //   this.$set(this.selectedDevicesLine, device.deviceInfo.deviceId, true);
        //   this.allDevicesLine.push(device);
        // });

        // let gatherDateArr = [];
        // this.loggerListLine.forEach((element) => {
        //   gatherDateArr.push(
        //     this.$options.filters.formatDateWithTimeZone(
        //       this.convertToTimestamp(element.gatherDate),
        //       this.setSelectedTimeZone,
        //       this.setSelectedLanguage
        //     )
        //   );
        // });
        // this.option.xData = gatherDateArr;
        // this.option.yAxis[0].name = this.$t("temperature");
        // this.option.yAxis[1].name = this.$t("humidity");
        // this.option.yAxis[0].yAxisIndex = 0;
        // this.option.yAxis[1].yAxisIndex = 1;

        // this.option.legend[0] =
        //   this.$t("temperature") + `(` + this.setSelectedTempType + `)`;
        // this.option.legend[1] = this.$t("humidity") + "(%)";

        // //处理温度
        // let tempArr = [];
        // // 收集温度数据并应用过滤器
        // tempArr = this.loggerListLine.map((element) => {
        //   return this.$options.filters.convertTemperature(
        //     Number(element.temp),
        //     this.setSelectedTempType
        //   );
        // });
        // console.log("tempArr: ", tempArr);
        // this.option.lineSeries[0].data = tempArr;

        // this.option.lineSeries[0].yAxisIndex = 0;
        // this.option.lineSeries[0].name =
        //   this.$t("temperature") + `(` + this.setSelectedTempType + `)`;
        // this.option.unit = this.setSelectedTempType;

        // //处理湿度
        // // let wetnessArr = [];
        // // this.lineDataList.forEach((element) => {
        // //   wetnessArr.push(Math.round(element.wetness));
        // // });
        // let wetnessArr = this.loggerListLine.map((element) => {
        //   return Math.round(element.wetness);
        // });
        // console.log("wetnessArr: ", wetnessArr);
        // this.option.lineSeries[1].data = wetnessArr;
        // this.option.lineSeries[1].yAxisIndex = 1;
        // this.option.lineSeries[1].name = this.$t("humidity") + "(%)";

        // this.tempConfig = r.data.tempConfig;

        // this.wetnessConfig = r.data.wetnessConfig;
        // //处理温度参考线
        // if (this.tempConfig) {
        //   const max = this.tempConfig.max;
        //   console.log("max: ", max);
        //   const min = this.tempConfig.min;
        //   console.log("min: ", min);
        //   this.option.yAxis[0].min = min - 10;
        //   this.option.yAxis[0].max = this.zoomValue;
        //   // 添加固定数值的参考线
        //   this.option.lineSeries[0].markLine.data.push(
        //     {
        //       yAxis: max, // 指定y轴上的位置为10
        //       symbol: "none", // 设置两端符号都为 'none'
        //       // symbolSize: 0, // 可选，定义符号大小
        //       label: {
        //         normal: {
        //           // show: true,
        //           position: "end",
        //           formatter: ({ value }) => this.getFormattedTemp(value),
        //         },
        //       },
        //       lineStyle: {
        //         color: "red",
        //         // width: 2,
        //         type: "dashed", // 可以是实线'solid'，虚线'dashed'，或者点线'dotted'
        //       },
        //     },
        //     {
        //       yAxis: min, // 指定y轴上的位置为10
        //       symbol: "none", // 设置两端符号都为 'none'
        //       // symbolSize: 0, // 可选，定义符号大小
        //       label: {
        //         normal: {
        //           // show: true,
        //           position: "end",
        //           formatter: ({ value }) => this.getFormattedTemp(value),
        //         },
        //       },
        //       lineStyle: {
        //         color: "blue",
        //         // width: 2,
        //         type: "dashed", // 可以是实线'solid'，虚线'dashed'，或者点线'dotted'
        //       },
        //     }
        //   );
        // } else {
        //   this.option.yAxis[0].min =
        //     Math.min(...tempArr) == Infinity ? 0 : Math.min(...tempArr);
        //   console.log("tempArr: ", JSON.stringify(tempArr));
        //   console.log("this.option.yAxis[0].min: ", this.option.yAxis[0].min);
        //   this.option.yAxis[0].max =
        //     Math.max(...tempArr) == -Infinity ? 30 : Math.max(...tempArr);
        //   console.log("this.option.yAxis[0].max: ", this.option.yAxis[0].max);
        // }

        // //处理湿度参考线
        // if (this.wetnessConfig) {
        //   const max = this.wetnessConfig.max;
        //   console.log("max: ", max);
        //   const min = this.wetnessConfig.min;
        //   console.log("min: ", min);
        //   this.option.yAxis[1].min = min;
        //   this.option.yAxis[1].max = max;
        //   // 添加固定数值的参考线
        //   this.option.lineSeries[1].markLine.data.push(
        //     {
        //       yAxis: max, // 指定y轴上的位置为10
        //       symbol: "none", // 设置两端符号都为 'none'
        //       // symbolSize: 0, // 可选，定义符号大小
        //       label: {
        //         normal: {
        //           // show: true,
        //           position: "end",
        //           formatter: max + "%",
        //         },
        //       },
        //       lineStyle: {
        //         color: "red",
        //         // width: 2,
        //         type: "dashed", // 可以是实线'solid'，虚线'dashed'，或者点线'dotted'
        //       },
        //     },
        //     {
        //       yAxis: min, // 指定y轴上的位置为10
        //       symbol: "none", // 设置两端符号都为 'none'
        //       // symbolSize: 0, // 可选，定义符号大小
        //       label: {
        //         normal: {
        //           // show: true,
        //           position: "end",
        //           formatter: min + "%",
        //         },
        //       },
        //       lineStyle: {
        //         color: "blue",
        //         // width: 2,
        //         type: "dashed", // 可以是实线'solid'，虚线'dashed'，或者点线'dotted'
        //       },
        //     }
        //   );
        // }
        // // else {
        // //   this.option.yAxis[1].min = Math.min(...wetnessArr) || 0;
        // //   console.log("wetnessArr: ", JSON.stringify(wetnessArr));
        // //   console.log("this.option.yAxis[1].min: ", this.option.yAxis[1].min);
        // //   this.option.yAxis[1].max = Math.max(...wetnessArr) || 100;
        // //   console.log("this.option.yAxis[1].max: ", this.option.yAxis[1].max);
        // // }

        // this.$nextTick(() => {
        //   this.$refs.chartRef.init(this.option);
        // });
      });
    },

    getShipmentAlarmConfig() {
      getShipmentAlarmConfigApi({ shipmentId: this.shipmentId }).then((r) => {
        // console.log("r: ", r);

        this.form.detailList = r.data.map((item) => {
          // console.log("item: ", JSON.stringify(item));
          // 处理单位字段
          item.unit = item.unit.toLowerCase() === "c" ? "°C" : "°F";
          // 将 source 和 type 转换为字符串
          item.source = String(item.source);
          item.type = String(item.type);
          return item;
        });
        //  this.$forceUpdate();
      });
    },

    downloadExcel() {
      let obj = {
        deviceId: this.deviceId,
        name: this.deviceBaseInfo.name,
      };
      console.log("obj: ", obj);

      this.goPages("/loggerInfoExport", {
        data: encodeURIComponent(JSON.stringify(obj)),
      });
    },
    // edit() {
    //   this.$refs.editRef.showDialog("", this.form.detailList, this.deviceId);
    // },

    // addToScene() {
    //   this.$refs.deviceToSceneRef.showDialog(this.deviceId);
    // },
    convertToTimestamp(dateString) {
      // 使用 moment 解析日期字符串
      const date = moment(dateString, "YYYY-MM-DD HH:mm");
      // 检查解析是否成功
      if (!date.isValid()) {
        console.error("Invalid date string:", dateString);
        return null;
      }
      // 获取时间戳（以毫秒为单位）
      const timestamp = date.valueOf();
      // 如果需要秒级时间戳，可以除以1000
      const timestampInSeconds = Math.floor(timestamp / 1000);
      return timestampInSeconds; // 或者返回 timestampInSeconds 如果你需要的是秒级时间戳
    },
    addToOrganization() {
      this.$refs.deviceToOrganRef.showDialog(this.deviceId);
    },
    toggleLoggerSelection(rows) {
      // console.log("rows: ", rows);
      if (rows) {
        rows.forEach((row) => {
          this.$refs.alarmTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.alarmTable.clearSelection();
      }
    },
    handleAlarmSelectionChange(val) {
      // console.log("val: ", val.deviceId);
      this.alarmSelection = val;
    },
    setRead() {
      // console.log(" this.alarmSelection: ", this.alarmSelection);
      if (this.alarmSelection.length <= 0) {
        this.$message.error(this.$t("please_select_alert_first"));
        return;
      }
      let temp = [];
      this.alarmSelection.forEach((element) => {
        temp.push(element.id);
      });
      let params = {
        idList: temp,
        deviceId: this.deviceId,
      };
      shipmentDetailAlarmCheckedApi(params).then((r) => {
        // console.log("=====", r);
        this.$message.success(this.$t("operation_successful"));
        // this.getAlarmList();
        this.getShipmentDetailAlarmList();
      });
    },
    getAlarmType() {
      selectApi({ type: "AlarmType" }).then((r) => {
        this.alarmType = r.data;
      });
    },
    getAlarmSensor() {
      selectApi({ type: "AlarmSensor" }).then((r) => {
        this.alarmSensor = r.data;
      });
    },
    getAlarmConditions() {
      selectApi({ type: "AlarmConditions" }).then((r) => {
        this.alarmConditions = r.data;
      });
    },
    handleCurrentChange(val) {
      this.pageNum = 1;
      this.pageNum = `${val}`;

      this.getAlarmList();
    },
    handleResize: _.debounce(function() {
      this.getShipmentDetailLineData();
    }, 500),
    handleIsShare(val) {
      // console.log("val: ", val);
      this.isShare = val;
    },
    showDurationSelector(rule) {
      // console.log("showTempUnitSelector---rule: ", rule);
      // 根据 rule.source 的值判断是否显示温度单位选择器
      return ["2"].includes(rule.type);
    },
    selectExistedRules() {
      this.$refs.existedRulesRef.showDialog(this.deviceId);
    },
    newRules() {
      this.goPages("/createAlert");
    },
    removeRule(item) {
      // console.log("item: ", item);
      let params = {
        deviceId: this.deviceId,
        id: item.id,
      };
      deleteAlarmSettingApi(params).then((r) => {
        // console.log("=====", r);
        this.$message.success(this.$t("delete_successful"));
        this.getShipmentAlarmConfig();
      });
    },
    getFormattedTemp(value) {
      return (
        this.$options.filters.convertTemperature(
          Number(value),
          this.setSelectedTempType
        ) + this.setSelectedTempType
      );
    },
    showTemperatureUnit(rule) {
      // console.log("showTemperatureUnit---rule: ", JSON.stringify(rule));
      // 根据 rule.source 的值判断是否显示温度单位选择器
      return ["2", "8", "16", "32", "64"].includes(rule.source);
    },

    getByShipmentId() {
      let params = {
        shipmentId: this.shipmentId,
        // pageSize: this.pageSize,
        // pageNum: this.pageNum,
      };
      // console.log("getByShipmentId---params: ", params);
      getByShipmentIdApi(params).then((r) => {
        // console.log("=====", r);
        this.shipmentInfo = r.data.shipmentInfo;
        // this.startTime=this.shipmentInfo.startTime;
        this.deviceList = r.data.deviceList;
      });
    },
    getShipmentOrgList() {
      let params = {
        shipmentId: this.shipmentId,
        // pageSize: this.pageSize,
        // pageNum: this.pageNum,
      };
      getShipmentOrgListApi(params).then((r) => {
        // console.log("=====", r);
        // this.shipmentInfo = r.data.shipmentInfo;
        // this.deviceList = r.data.deviceList;
        this.orgList = r.data;
      });
    },

    getShipmentDetailAlarmList() {
      let params = {
        shipmentId: this.shipmentId,
        pageSize: this.pageSize,
        pageNum: this.pageNum,
        readInd: this.readInd, // 0:未读 1：已读
        startTime: 0,
        endTime: 0,
      };
      getShipmentDetailAlarmListApi(params).then((r) => {
        // console.log("getShipmentDetailAlarmListApi=====", r);
        this.alarmList = r.data.records;

        // this.shipmentInfo = r.data.shipmentInfo;
        // this.deviceList = r.data.deviceList;
        // this.orgList = r.data;
      });
    },

    //  getAlarmList() {
    //   let params = {
    //     pageSize: this.pageSize,
    //     pageNum: this.pageNum,
    //     timezone: this.timezone,
    //     startDate: this.startDate,
    //     endDate: this.endDate,
    //     deviceId: this.deviceId,
    //   };
    //   getAlarmListApi(params).then((r) => {
    //     console.log("获取告警详情列表=====", r);
    //     this.alarmList = r.data.records;
    //     this.total = r.data.total;
    //     console.log("告警详情列表数据条数: ", r.data.length);
    //   });
    // },

    handleClick(val) {
      console.log("handleClick---val: ", val.name);
      this.readInd = val.name;
      this.getShipmentDetailAlarmList();
    },
    // 异步加载 Google Maps API
    loadGoogleMapsAPI(callback) {
      var script = document.createElement("script");
      script.src =
        "https://maps.googleapis.com/maps/api/js?key=AIzaSyAzPvwkS71_zXd53e_yOPBj2eeimWqr8rg&callback=initGoogleMap";
      script.async = true;

      // 添加到文档头部
      document.head.appendChild(script);

      // 设置回调函数
      window.initGoogleMap = function() {
        callback();
      };
    },
    loadMap() {
      if (this.isChina) {
        if (typeof window.BMapGL !== "undefined") {
          this.initBaiduMap();
        } else {
          setTimeout(() => {
            if (typeof window.BMapGL !== "undefined") {
              this.initBaiduMap();
            } else {
              console.error("加载百度地图失败！");
            }
          }, 2000); // 等待 2 秒后再次检查
        }
      } else {
        this.loadGoogleMapsAPI(this.initGoogleMap);
      }
    },

    initBaiduMap() {
      var map = new window.BMapGL.Map("container"); // 创建地图实例
      var point = new window.BMapGL.Point(116.404, 39.915); // 创建点坐标
      map.centerAndZoom(point, 5); // 初始化地图，设置中心点坐标和地图级别
      map.enableScrollWheelZoom(true); //开启鼠标滚轮缩放

      // 如果你想单独使用放大缩小按钮，可以使用 ZoomControl 控件
      var zoomControl = new window.BMapGL.ZoomControl({
        anchor: window.BMAP_ANCHOR_BOTTOM_RIGHT, // 设置控件停靠位置
      });
      map.addControl(zoomControl);
      // // 添加全屏控件
      //       var fullscreenControl = new window.BMapGL.FullscreenControl({
      //         anchor: window.BMapGL_ANCHOR_TOP_RIGHT, // 设置控件停靠位置
      //         offset: new window.BMapGL.Size(10, 10) // 设置控件偏移量
      //       });
      //       map.addControl(fullscreenControl);

      console.log("this.loggerList: ", JSON.stringify(this.loggerList));
      // 收集所有需要连接的点
      var allPoints = [];
      // 遍历loggerList数组
      for (let i = 0; i < this.loggerList.length; i++) {
        // 再次遍历每个logger的mapList数组
        for (let j = 0; j < this.loggerList[i].mapList.length; j++) {
          var cityPoint = new window.BMapGL.Point(
            this.loggerList[i].mapList[j].longitude,
            this.loggerList[i].mapList[j].latitude
          );
          allPoints.push(cityPoint);
          let that = this;
          // 定义图标（这里假设withAlertIcon是已经定义好的图标URL）
          var myIcon = new window.BMapGL.Icon(
            locationIcon,
            new window.BMapGL.Size(32, 32),
            {
              offset: new window.BMapGL.Size(18, 30), // 设置偏移量，使图标在线的上方一点点
            }
          );

          // 创建Marker
          var marker = new window.BMapGL.Marker(cityPoint, { icon: myIcon });

          // 将Marker添加到地图
          map.addOverlay(marker);

          // // 为Marker添加点击事件
          // (function(marker, index, subIndex) {
          //   marker.addEventListener("click", function(e) {
          //     // 信息窗口逻辑...
          //     console.log(`Marker ${index + 1}-${subIndex + 1} clicked`);

          //     // 创建并显示信息窗口
          //     var infoWindowContent = `<div>...信息窗口内容...</div>`;
          //     var opts = { width: 400, height: 250 };
          //     var infoWindow = new window.BMapGL.InfoWindow(
          //       infoWindowContent,
          //       opts
          //     );
          //     map.openInfoWindow(infoWindow, e.point);
          //   });
          // })(marker, i, j); // 注意这里的参数

          // 为Marker添加点击事件
          marker.addEventListener(
            "click",
            (function(index) {
              return function(e) {
                // console.log("Marker " + (index + 1) + " clicked");
                // console.log(
                //   " that.loggerList[i]: ",
                //   JSON.stringify(that.loggerList[i])
                // );
                // console.log(
                //   " that.loggerList[i]: ",
                //   JSON.stringify(that.loggerList[index + 1])
                // );

                // 如果有当前打开的信息窗口，先关闭它
                if (that.currentInfoWindow) {
                  that.currentInfoWindow.close();
                }

                // 创建并显示信息窗口
                var infoWindowContent =
                  `<div style="height:20px;background:#FFFFFF;line-height:20px;text-align:left;">
                    <div>
                       <span style="font-weight: bold">` +
                  that.loggerList[i].deviceInfo.name +
                  `</span><br>` +
                  `ID#` +
                  that.loggerList[i].deviceInfo.deviceId +
                  `</div></br>
                    <div>
                        <span style="font-weight: bold"> ` +
                  // that.$t("latest_measurements") +
                  // `:` +
                  // that.$options.filters.formatDateWithTimeZone(
                  //   that.loggerList[i].refreshDate,
                  //   that.setSelectedTimeZone,
                  //   that.setSelectedLanguage
                  // ) +
                  // `</span>
                  //   </div>
                  //   <div>` +
                  // that.$options.filters.convertTemperature(
                  //   Number(that.loggerList[i].temp),
                  //   that.setSelectedTempType
                  // ) +
                  // that.setSelectedTempType +
                  // ` ` +
                  // that.loggerList[i].wetness +
                  // `</div></br>
                  //   <div>
                  //       <span style="font-weight: bold">Last location:</span>
                  //   </div>
                  // <div>` +
                  // that.loggerList[i].address +
                  // `</div></br>
                  // <div>
                  //       <span style="font-weight: bold">` +
                  // that.$t("alert") +
                  // `:</span></br>` +
                  // that.loggerList[i].alarmNum +
                  `</div>
                 </div>`;

                var opts = {
                  // width: 400,
                  // height: 250,
                  width: 150,
                  height: 50,
                  // title: that.loggerList[i].name,
                };
                var infoWindow = new window.BMapGL.InfoWindow(
                  infoWindowContent,
                  opts
                );
                this.openInfoWindow(infoWindow, e.point); // 打开信息窗口在Marker的位置
              };
            })(marker, i, j)
          ); // 传递i给立即执行的函数表达式
        }
      }
      // console.log("allPoints: ", JSON.stringify(allPoints));

      // 创建Polyline对象
      // if (allPoints.length > 0) {
      //   var polyline = new window.BMapGL.Polyline(allPoints, {
      //     strokeColor: "red", // 线条颜色
      //     strokeWeight: 2, // 线条宽度
      //     strokeOpacity: 0.5, // 线条透明度
      //   });
      //   // 将Polyline添加到地图
      //   map.addOverlay(polyline);
      // }
    },
    initGoogleMap() {
      // 创建地图实例
      var map = new window.google.maps.Map(
        document.getElementById("container2"),
        {
          center: { lat: 39.915, lng: 116.404 },
          zoom: 4,
          mapTypeId: window.google.maps.MapTypeId.ROADMAP,
          mapTypeControl: false,
          fullscreenControl: false,
          streetViewControl: false,
          disableDefaultUI: false,
        }
      );

      // 开启滚轮缩放
      // map.settings.scrollwheel = true;

      // 遍历坐标点数组，为每个坐标点创建Marker并添加到地图上
      // console.log("this.loggerList: ============", this.loggerList);

      // 收集所有需要连接的点
      var allPoints = [];

      this.loggerList.forEach((element) => {
        element.latitude = Number(element.latitude);
        element.longitude = Number(element.longitude);
      });
      for (let i = 0; i < this.loggerList.length; i++) {
        for (let j = 0; j < this.loggerList[i].mapList.length; j++) {
          var cityPoint = {
            lat: parseFloat(this.loggerList[i].mapList[j].latitude),
            lng: parseFloat(this.loggerList[i].mapList[j].longitude),
          };
          allPoints.push(cityPoint);

          let that = this;
          // var iconUrl =
          //   that.loggerList[i].alarmInd === 1 ? withAlertIcon : withoutAlertIcon;

          // 创建一个Icon实例
          var myIcon = {
            url: locationIcon,
            // size: new window.google.maps.Size(100, 100),
            scaledSize: new window.google.maps.Size(50, 50), // 图标在地图上显示的大小
            origin: new window.google.maps.Point(0, 0), // 图标原点
            anchor: new window.google.maps.Point(24, 48), // 锚点
          };

          var marker = new window.google.maps.Marker({
            position: cityPoint,
            map: map,
            icon: myIcon,
          });

          // 为Marker添加点击事件
          window.google.maps.event.addListener(
            marker,
            "click",
            (function(marker, i) {
              return function() {
                // console.log("Marker " + (i + 1) + " clicked");

                // 如果有当前打开的信息窗口，先关闭它
                if (that.currentInfoWindow) {
                  that.currentInfoWindow.close();
                }

                // 创建并显示信息窗口
                var infoWindowContent =
                  `
          <div style="height:50px;width:150px;background:#FFFFFF;">
            <div><span style="font-weight: bold">` +
                  that.loggerList[i].deviceInfo.name +
                  `</span><br>ID#` +
                  that.loggerList[i].deviceInfo.deviceId +
                  //       `</div>
                  // <div><br><span style="font-weight: bold">` +
                  //       that.$t("latest_measurements") +
                  //       `</span> ` +
                  //       that.$options.filters.formatDateWithTimeZone(
                  //         that.loggerList[i].refreshDate,
                  //         that.setSelectedTimeZone,
                  //         that.setSelectedLanguage
                  //       ) +
                  //       `:</div>
                  // <div>` +
                  //       that.$options.filters.convertTemperature(
                  //         that.loggerList[i].temp,
                  //         that.setSelectedTempType
                  //       ) +
                  //       that.setSelectedTempType +
                  //       ` ` +
                  //       that.loggerList[i].wetness +
                  //       `</div>
                  // <div><br><span style="font-weight: bold">Last location:</span></div>
                  // <div>` +
                  //       that.loggerList[i].address +
                  //       `</div>
                  // <div><span style="font-weight: bold"><br>` +
                  //       that.$t("alert") +
                  //       `:</span></div>
                  // <div>` +
                  //       that.loggerList[i].alarmNum +
                  `</div>
          </div>`;

                var infoWindow = new window.google.maps.InfoWindow({
                  content: infoWindowContent,
                  // maxWidth: 150,
                });

                infoWindow.open(map, marker);
                that.currentInfoWindow = infoWindow;
              };
            })(marker, i, j)
          );
        }
      }
      // 创建Polyline对象
      // if (allPoints.length > 0) {
      //   var polyline = new window.google.maps.Polyline({
      //     path: allPoints,
      //     geodesic: true,
      //     strokeColor: "#FF0000",
      //     strokeOpacity: 1.0,
      //     strokeWeight: 2,
      //   });

      //   // 将Polyline添加到地图
      //   polyline.setMap(map);
      // }
    },

    changeZoomOption(val) {
      // console.log("val: ", val);
      switch (val) {
        case "1":
          this.$refs.chartRef.changeHeight("500px");
          this.zoomValue = 40;
          break;
        case "2":
          this.$refs.chartRef.changeHeight("650px");
          this.zoomValue = 55;
          break;
        case "3":
          this.$refs.chartRef.changeHeight("800px");
          this.zoomValue = 60;
          break;

        default:
          break;
      }
    },
    shipmentEnd() {
      shipmentEndApi({ shipmentId: this.shipmentId }).then((r) => {
        // console.log("shipmentEndApi=====", r);
        this.$message.success(this.$t("operation_successful"));
      });
    },

    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      // console.log("column: ", column);
      // console.log("row: ", row);
      if (columnIndex === 7) {
        // 假设 Duration 列是最后一列
        if (rowIndex === 0) {
          return {
            rowspan: this.totalList.length,
            colspan: 1,
          };
        } else {
          return {
            rowspan: 0,
            colspan: 0,
          };
        }
      }
    },
    goDeviceDetail(row) {
      // console.log("row: ", row);
      let obj = {
        deviceId: row.deviceId,
      };
      this.goPages("/loggerDetail", {
        data: encodeURIComponent(JSON.stringify(obj)),
      });
    },

    selectDevice(deviceId) {
      console.log(
        "筛选之前---this.loggerList: ",
        JSON.stringify(this.loggerList)
      );

      // 创建临时数组
      let loggerListTemp = [...this.loggerList];

      // 检查设备是否已存在
      const isDeviceSelected = loggerListTemp.some(
        (device) => device.deviceInfo.deviceId === deviceId
      );

      if (isDeviceSelected) {
        // 如果设备已存在，则移除它
        loggerListTemp = loggerListTemp.filter(
          (device) => device.deviceInfo.deviceId !== deviceId
        );
        this.selectedDevices[deviceId] = false;

        // 检查是否有任何设备未被选中
        if (
          !this.allDevices.every(
            (device) => this.selectedDevices[device.deviceInfo.deviceId]
          )
        ) {
          this.selectAll = false;
        }
      } else {
        // 如果设备不存在，则添加它
        const selectedDevice = this.allDevices.find(
          (device) => device.deviceInfo.deviceId === deviceId
        );
        if (selectedDevice) {
          loggerListTemp.push(selectedDevice);
          this.selectedDevices[deviceId] = true;
          // 检查是否所有设备都被选中
          if (
            this.allDevices.every(
              (device) => this.selectedDevices[device.deviceInfo.deviceId]
            )
          ) {
            this.selectAll = true;
          }
        } else {
          // console.log("没有找到匹配的设备");
        }
      }

      console.log(
        "筛选之后---loggerListTemp: ",
        JSON.stringify(loggerListTemp)
      );

      // 将临时数组的结果赋值给 loggerList
      this.loggerList = loggerListTemp;
      this.loadMap();
    },
    selectAllDevices() {
      if (this.selectAll) {
        // 全选
        this.allDevices.forEach((device) => {
          this.selectedDevices[device.deviceInfo.deviceId] = true;
        });
        this.loggerList = [...this.allDevices];
      } else {
        // 全不选
        this.allDevices.forEach((device) => {
          this.selectedDevices[device.deviceInfo.deviceId] = false;
        });
        this.loggerList = [];
      }
      this.loadMap();
    },

    selectAllDevicesLine() {
      if (this.selectAllLine) {
        // 全选
        this.allDevicesLine.forEach((device) => {
          this.selectedDevicesLine[device.deviceInfo.deviceId] = true;
        });
        this.loggerListLine = [...this.allDevicesLine];
        console.log(
          "全选loggerListLine: ",
          JSON.stringify(this.loggerListLine)
        );
      } else {
        // 全不选
        this.allDevicesLine.forEach((device) => {
          this.selectedDevicesLine[device.deviceInfo.deviceId] = false;
        });
        this.loggerListLine = [];
        console.log(
          "全选取消loggerListLine: ",
          JSON.stringify(this.loggerListLine)
        );
      }

      this.updateChart();
    },

    // 选择单个设备
    selectDeviceLine(deviceId) {
      let loggerListTemp = [...this.loggerListLine];

      const isDeviceSelected = loggerListTemp.some(
        (device) => device.deviceInfo.deviceId === deviceId
      );

      if (isDeviceSelected) {
        loggerListTemp = loggerListTemp.filter(
          (device) => device.deviceInfo.deviceId !== deviceId
        );
        this.selectedDevicesLine[deviceId] = false;

        if (
          !this.allDevicesLine.every(
            (device) => this.selectedDevicesLine[device.deviceInfo.deviceId]
          )
        ) {
          this.selectAllLine = false;
        }
      } else {
        const selectedDevice = this.allDevicesLine.find(
          (device) => device.deviceInfo.deviceId === deviceId
        );
        if (selectedDevice) {
          loggerListTemp.push(selectedDevice);
          this.selectedDevicesLine[deviceId] = true;

          if (
            this.allDevicesLine.every(
              (device) => this.selectedDevicesLine[device.deviceInfo.deviceId]
            )
          ) {
            this.selectAllLine = true;
          }
        } else {
          console.log("没有找到匹配的设备");
        }
      }

      this.loggerListLine = loggerListTemp;
      console.log("单选loggerListLine: ", JSON.stringify(this.loggerListLine));

      this.updateChart();
    },

    updateChart() {
      const gatherDateArr = this.loggerListLine.flatMap((device) =>
        device.lineList.map((item) =>
          this.$options.filters.formatDateWithTimeZone(
            this.convertToTimestamp(item.gatherDate),
            this.setSelectedTimeZone,
            this.setSelectedLanguage
          )
        )
      );
      this.option.bottomPosition = "10%";
      this.option.xData = gatherDateArr;
      this.option.yAxis[0].name = this.$t("temperature");
      this.option.yAxis[1].name = this.$t("humidity");

      // const tempArr = this.loggerListLine.flatMap((device) =>
      //   device.lineList.map((item) =>
      //     this.$options.filters.convertTemperature(
      //       Number(item.temp),
      //       this.setSelectedTempType
      //     )
      //   )
      // );
      // // this.option.color[0] = this.getRandomColor();
      // this.option.lineSeries[0].data = tempArr;
      // this.option.lineSeries[0].yAxisIndex = 0;
      // this.option.lineSeries[0].name =
      //   this.$t("temperature") + `(` + this.setSelectedTempType + `)`;
      // this.option.legend[0] =
      //   this.$t("temperature") + `(` + this.setSelectedTempType + `)`;
      // const wetnessArr = this.loggerListLine.flatMap((device) =>
      //   device.lineList.map((item) => Math.round(item.wetness))
      // );

      // // this.option.color[1] = this.getRandomColor();
      // this.option.lineSeries[1].data = wetnessArr;
      // this.option.lineSeries[1].yAxisIndex = 1;
      // this.option.lineSeries[1].name = this.$t("humidity") + "(%)";
      // this.option.legend[1] = this.$t("humidity") + "(%)";

      // 初始化 lineSeries 和 legend
      this.option.lineSeries = [];
      // this.option.legend = { data: [], bottom: 0 };

      // 遍历每个设备
      this.loggerListLine.forEach((device, index) => {
        // 处理温度数据
        const tempArr = device.lineList.map((item) =>
          this.$options.filters.convertTemperature(
            Number(item.temp),
            this.setSelectedTempType
          )
        );

        // 创建温度 series
        this.option.lineSeries.push({
          // name: `${device.deviceInfo.deviceId}-`+this.$t("temperature"),
          name: `${device.deviceInfo.name}-${device.deviceInfo.deviceId}`,
          type: "line",
          smooth: true,
          data: tempArr,
          yAxisIndex: 0,
          markLine: {
            data: [],
          },
          itemStyle: {
            color: this.getRandomColor(),
          },
          icon: "circle",
        });

        // 添加温度 legend
        // this.option.legend.push(`${device.deviceInfo.deviceId}-`+this.$t("temperature"));
        this.option.legend.push(
          `${device.deviceInfo.name}-${device.deviceInfo.deviceId}`
        );

        // 处理湿度数据
        const wetnessArr = device.lineList.map((item) =>
          Math.round(Number(item.wetness))
        );

        // 创建湿度 series
        this.option.lineSeries.push({
          // name: `${device.deviceInfo.deviceId}-`+this.$t("humidity"),
          name: `${device.deviceInfo.name}-${device.deviceInfo.deviceId}`,
          type: "line",
          smooth: true,
          data: wetnessArr,
          yAxisIndex: 1,
          markLine: {
            data: [],
          },
          itemStyle: {
            color: this.getRandomColor(),
          },
        });

        // 添加湿度 legend
        // this.option.legend.push(`${device.deviceInfo.deviceId}-`+this.$t("humidity"));
        this.option.legend.push(
          `${device.deviceInfo.name}-${device.deviceInfo.deviceId}`
        );
      });

      // 更新图表配置
      this.option = { ...this.option };

      //处理温度参考线
      if (this.tempConfig) {
        const max = this.tempConfig.max;
        // console.log("max: ", max);
        const min = this.tempConfig.min;
        // console.log("min: ", min);
        this.option.yAxis[0].min = min - 10;
        this.option.yAxis[0].max = this.zoomValue;
        // 添加固定数值的参考线
        this.option.lineSeries[0].markLine.data.push(
          {
            yAxis: max, // 指定y轴上的位置为10
            symbol: "none", // 设置两端符号都为 'none'
            // symbolSize: 0, // 可选，定义符号大小
            label: {
              normal: {
                // show: true,
                position: "end",
                formatter: ({ value }) => this.getFormattedTemp(value),
              },
            },
            lineStyle: {
              color: "red",
              // width: 2,
              type: "dashed", // 可以是实线'solid'，虚线'dashed'，或者点线'dotted'
            },
          },
          {
            yAxis: min, // 指定y轴上的位置为10
            symbol: "none", // 设置两端符号都为 'none'
            // symbolSize: 0, // 可选，定义符号大小
            label: {
              normal: {
                // show: true,
                position: "end",
                formatter: ({ value }) => this.getFormattedTemp(value),
              },
            },
            lineStyle: {
              color: "blue",
              // width: 2,
              type: "dashed", // 可以是实线'solid'，虚线'dashed'，或者点线'dotted'
            },
          }
        );
      } else {
        this.option.yAxis[0].min =
          Math.min(...tempArr) == Infinity ? 0 : Math.min(...tempArr);
        // console.log("tempArr: ", JSON.stringify(tempArr));
        // console.log("this.option.yAxis[0].min: ", this.option.yAxis[0].min);
        this.option.yAxis[0].max =
          Math.max(...tempArr) == -Infinity ? 30 : Math.max(...tempArr);
        // console.log("this.option.yAxis[0].max: ", this.option.yAxis[0].max);
      }

      //处理湿度参考线
      if (this.wetnessConfig) {
        const max = this.wetnessConfig.max;
        // console.log("max: ", max);
        const min = this.wetnessConfig.min;
        // console.log("min: ", min);
        this.option.yAxis[1].min = min;
        this.option.yAxis[1].max = max;
        // 添加固定数值的参考线
        this.option.lineSeries[1].markLine.data.push(
          {
            yAxis: max, // 指定y轴上的位置为10
            symbol: "none", // 设置两端符号都为 'none'
            // symbolSize: 0, // 可选，定义符号大小
            label: {
              normal: {
                // show: true,
                position: "end",
                formatter: max + "%",
              },
            },
            lineStyle: {
              color: "red",
              // width: 2,
              type: "dashed", // 可以是实线'solid'，虚线'dashed'，或者点线'dotted'
            },
          },
          {
            yAxis: min, // 指定y轴上的位置为10
            symbol: "none", // 设置两端符号都为 'none'
            // symbolSize: 0, // 可选，定义符号大小
            label: {
              normal: {
                // show: true,
                position: "end",
                formatter: min + "%",
              },
            },
            lineStyle: {
              color: "blue",
              // width: 2,
              type: "dashed", // 可以是实线'solid'，虚线'dashed'，或者点线'dotted'
            },
          }
        );
      }
      // else {
      //   this.option.yAxis[1].min = Math.min(...wetnessArr) || 0;
      //   console.log("wetnessArr: ", JSON.stringify(wetnessArr));
      //   console.log("this.option.yAxis[1].min: ", this.option.yAxis[1].min);
      //   this.option.yAxis[1].max = Math.max(...wetnessArr) || 100;
      //   console.log("this.option.yAxis[1].max: ", this.option.yAxis[1].max);
      // }

      this.$nextTick(() => {
        this.$refs.chartRef.init(this.option);
      });
    },

    getRandomColor() {
      const letters = "0123456789ABCDEF";
      let color = "#";
      for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      return color;
    },
  },
  props: {},
  components: {
    // ShareLoggerDialog,
    ChangeLoggerNameDialog,
    // ChangeLoggerRemarkDialog,
    StackedAreaChart,
    // ChangeAlarmConditionsDialog,
    // TransferDeviceDialog,
    // AddDeviceToOrganDialog,
    // AddDeviceToSceneDialog,
    AddCommentDialog,
    UpdateShipmentOrgDialog,
    // SelectExistedRulesDialog,
    LoggerCard,
  },
  computed: {
    setSelectedTimeZone() {
      return this.$store.getters.selectedTimeZone;
    },
    setSelectedTempType() {
      return this.$store.getters.selectedTempType;
    },
    setSelectedLanguage() {
      return this.$store.getters.selectedLanguage;
    },
    chartWidth() {
      return `${window.innerWidth / 2}px`;
    },
    stepLineWidth() {
      return `${window.innerWidth / 4}px`;
    },
  },
  watch: {
    setSelectedTimeZone(newVal) {
      // console.log("newVal: ", newVal);
      this.timezone = newVal;
      this.getShipmentDetailAlarmList();
      this.getShipmentDetailLineData();
      this.getShipmentDetailDeviceData();
    },

    setSelectedTempType(newVal) {
      if (newVal) {
        this.getShipmentDetailLineData();
        this.getShipmentDetailDeviceData();
      }
    },
    setSelectedLanguage(newVal) {
      // console.log("setSelectedLanguage---newVal: ", newVal);
      this.$i18n.locale = newVal;
      this.getShipmentDetailLineData();
      this.getShipmentDetailDeviceData();
    },
  },

  mounted() {
    this.$i18n.locale = this.setSelectedLanguage;
    this.isChina = JSON.parse(localStorage.getItem("isChina"));
    // console.log("this.isChina: ", this.isChina);
    this.timezone = this.setSelectedTimeZone;
    let obj = JSON.parse(decodeURIComponent(this.$route.query.data));
    // console.log("obj====: ", obj);
    this.deviceId = obj.deviceId;
    this.shipmentId = obj.shipmentId;
    // console.log("this.shipmentId: ", this.shipmentId);
    // console.log("this.deviceId:", this.deviceId);
    // this.clickTab(1);
    // this.getDetailInfo();
    // this.getDetailSummary();
    // this.getAlarmRules();
    // this.getAlarmList();
    this.getAlarmType();
    this.getAlarmSensor();
    // this.getAlarmConditions();
    this.getByShipmentId();
    this.getShipmentOrgList();
    this.getShipmentDetailAlarmList();
    this.getShipmentAlarmConfig();
    // this.getShipmentDetailLineData();
    this.getShipmentDetailDeviceData();
    // console.log("this.chartWidth: ", this.chartWidth);
    this.$refs.chartRef.init(this.option);
    this.loadMap();
  },

  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  beforeDestroy() {},

  created() {
    window.addEventListener("resize", this.handleResize);
  },
};
</script>

<style scoped lang="less">
.container {
  padding: 10px 68px 0 38px;
  display: flex;
  flex-direction: column;
  .el-button--primary {
    background: #1a72f4;
    border-color: #1a72f4;
  }
  .top-box {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .left-box {
      display: flex;
      flex-direction: column;
      .left-top-box {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .left-status-box {
          width: 320px;
          height: 150px;
          display: flex;
          border-radius: 8px;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          img {
            width: 50px;
            height: 50px;
          }
          span {
            color: white;
            margin-top: 16px;
            font-size: 16px;
          }
          .alert-text {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin-left: 10px;
            .text {
              font-size: 24px;
              color: white;
            }
          }
        }
        .left-info-box {
          display: flex;
          flex-direction: column;
          padding: 0px 20px;
          // justify-content: space-between;

          .info-item {
            display: flex;
            flex-direction: row;
            align-items: center;
            font-size: 18px;
            span {
              font-size: 18px;
            }
            img {
              cursor: pointer;
              width: 15px;
              margin: 15px;
            }
            .more-style {
              color: #409eff;
              cursor: pointer;
            }
          }
        }
      }
      .left-bottom-box {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 20px;
        .operation-stop-btn {
          display: flex;
          flex-direction: row;
          align-items: center;
          border-radius: 4px;
          padding: 0 20px;
          background: #808080;
          text-align: center;
          cursor: pointer;
          border: 1px solid #808080;

          img {
            margin-right: 5px;
            width: 20px;
            height: 20px;
          }
          span {
            color: white;
            font-weight: 500;
          }
        }
      }
    }
    .right-box {
      .table-container {
        border-collapse: collapse; /* 合并边框线 */
        border-spacing: 0; /* 去除单元格间隔 */
        border-radius: 8px; /* 设置圆角 */
        overflow: hidden; /* 隐藏超出部分，防止圆角被超出内容破坏 */
        background-color: #fff; /* 背景颜色与表格相同以隐藏间隙 */
        border: 1px solid #bbbbbb; /* 边框，与表格边框相同 */

        table {
          width: 600px;
        }
        .gray-cell {
          background-color: #f2f2f2;
          padding: 15px;
          // border: 1px solid #bbbbbb;
          border-bottom: 1px solid #bbbbbb !important; /* 保留单元格的边框 */
          border-right: 1px solid #bbbbbb !important; /* 保留单元格的边框 */
          img {
            width: 12px;
            margin-left: 10px;
            cursor: pointer;
          }
        }
        .label {
          width: 100px;
          color: black;
          font-weight: 500;
        }

        .white-cell {
          background-color: #fff;
          padding: 15px;
          // border: 1px solid #bbbbbb;
          border-bottom: 1px solid #bbbbbb !important; /* 保留单元格的边框 */
          border-right: 1px solid #bbbbbb !important; /* 保留单元格的边框 */
        }
      }
    }
  }

  .alert-box {
    background: white;
    padding: 20px;
    border-radius: 8px;

    .table-title {
      margin: 10px 0;
    }

    .alert-title {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin: 10px 0;
      span {
        font-size: 28px;
        font-weight: 500;
      }
    }
  }
  .rules-box {
    margin: 20px 0;
    background: white;
    padding: 20px;
    border-radius: 8px;

    .rules-title {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      // align-items: center;
      margin-bottom: 20px;
      .left {
        display: flex;
        flex-direction: column;
        .font-light {
          color: #9b9b9b;
          font-size: 14px;
        }
      }
      .right {
        display: flex;
        flex-direction: row;
        .el-button--primary {
          background: #1a72f4;
          border-color: #1a72f4;
          height: 40px;
        }
      }
    }
    span {
      font-size: 28px;
      font-weight: 500;
    }
  }
  .echart-box {
    margin: 20px 0;
    padding: 20px;
    background: white;
    border-radius: 8px;

    span {
      font-size: 28px;
      font-weight: 500;
    }
    .zoom-style {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      .left {
        display: flex;
        flex-direction: row;
        align-items: center;
        .tab-box {
          margin: 10px 0;
          display: flex;
          background: white;
          flex-direction: row;
          align-items: center;
          line-height: 40px;
          height: 20px;
          padding-right: 10px;
        }
        .tab-item {
          font-size: 14px;
          text-align: center;
          color: white;
          justify-content: center;
          background: #bbbaba;
          height: 24px;
          line-height: 24px;
          width: 60px;
          border-radius: 2px;
          margin: 0 5px;
          cursor: pointer;
        }

        .is-active {
          background: #1a72f4;
          font-size: 16px;
          font-weight: Normal;
          text-align: center;
          color: #ffffff;
        }
      }
      .right {
        display: flex;
        flex-direction: row;
        align-items: center;
        .date-tip {
          font-size: 18px;
          margin: 0 10px;
        }
      }
    }
    .chart {
      position: relative;
      top: 10px;
      .additional-div {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        position: absolute;
        right: 0;
        top: 0;
        height: 14px;
        line-height: 14px;

        margin-top: 18px;
        img {
          width: 15px;
          height: 15px;
        }
        .all-null {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
          span {
            color: #333333;
            font-size: 14px;
            margin-top: 2px;
          }
        }
        .not-null {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;

          .high {
            color: #333333;
            margin: 0 5px;
            margin-top: 2px;
            span {
              color: #fc5c5b;
              font-size: 14px;
              margin-top: 2px;
            }
          }
          .low {
            color: #333333;
            margin: 0 5px;
            margin-top: 2px;
            span {
              color: #6464ff;
              font-size: 14px;
              margin-top: 2px;
            }
          }
        }
      }
    }
  }
  .statistics-box {
    margin: 20px 0;
    padding: 20px;
    background: white;
    border-radius: 8px;

    span {
      font-size: 28px;
      font-weight: 500;
    }
    table {
      width: 100%;
      margin-bottom: -5px;
      margin: 20px 0;
    }
    .white-cell {
      background: white;
      width: 25%;
      padding: 10px;
      border: 1px solid #bbbbbb;
      text-align: center;
    }
    .device-title {
      background: #e8e9ef;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
      border: 1px solid #bbbbbb;
      display: flex;
      flex-direction: column;
      margin-top: 20px;
      .title {
        padding: 20px;
        // border-bottom: 1px solid #bbbbbb;
      }
    }
  }
  .summary-box {
    background: white;
    padding: 20px;
    border-radius: 8px;

    span {
      font-size: 28px;
      font-weight: 500;
    }

    table {
      width: 100%;
      margin-bottom: -5px;
      margin: 20px 0;
    }
    .white-cell {
      background: white;
      width: 33.33%;
      padding: 10px;
      border: 1px solid #bbbbbb;
      text-align: center;
    }
  }
  .device-list-style {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    // margin-top: 20px;
    background: white;
  }
  .device-card {
    flex: 1 0 30%; /* 每个设备占30%的宽度 */
    max-width: 30%; /* 最大宽度为30% */
    box-sizing: border-box;
    // padding: 20px;
    // margin: 20px;
    cursor: pointer;
    height: 400px;
  }

  // .device-card:hover {
  //   background-color: #f0f0f0;
  // }
}
.chart {
  width: 97%;
  color: white;
}
.comment-box {
  display: flex;
  justify-content: center;

  .add-comment {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 168px;
    height: 32px;
  }
}
.rule-box {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border: 1px solid rgba(187, 187, 187, 1);
  padding: 5px 10px;
  height: 59px;
  line-height: 59px;
  border-radius: 5px;
  margin-left: -120px;
  img {
    width: 33px;
    height: 33px;
  }
  .selects-container {
    display: flex;
    gap: 8px;
    align-items: center;
    .select-item {
      width: 220px;
      height: 47px;
      line-height: 47px;
    }
    .input-item {
      width: 100px;
      height: 47px;
      line-height: 47px;
    }
  }

  /deep/.el-select .el-input.is-disabled .el-input__inner {
    color: #606266;
  }
  /deep/.el-input.is-disabled .el-input__inner {
    color: #606266;
  }
  .custom-placeholder /deep/.el-input__inner::-webkit-input-placeholder {
    color: #606266;
  }
  .custom-placeholder /deep/.el-input__inner:-ms-input-placeholder {
    color: #606266;
  }
  .custom-placeholder /deep/.el-input__inner::placeholder {
    color: #606266;
  }
}
.desc3 {
  margin: 15px 0 10px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.map {
  width: 100%;
  // background: #848484;
  height: 499px;
  // color: white;
}
.line-bottom-box {
  display: flex;
  flex-direction: row;

  .bottom {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 10px 0;
    flex-wrap: wrap;
    img {
      width: 20px;
      height: 20px;
      padding: 0 5px;
    }
    span {
      font-size: 14px;
    }
  }
  /deep/ .el-checkbox .el-checkbox__inner {
    display: none;
  }
}

.bottom-box {
  display: flex;
  flex-direction: row;

  .bottom {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 10px 0;
    img {
      width: 20px;
      height: 20px;
      padding: 0 5px;
    }
  }
  /deep/ .el-checkbox .el-checkbox__inner {
    display: none;
  }
}

/deep/.el-checkbox .custom-img {
  width: 20px; /* 根据需要设置图片大小 */
  height: 20px;
  vertical-align: middle; /* 确保图片与文字垂直居中 */
  margin-right: 5px; /* 图片与文字间的距离 */
}
.step-box {
  display: flex;
  height: 100px;
  .step-start {
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: space-between;

    .green-box {
      display: flex;
      flex-direction: row;
      align-items: center;
      .green-point {
        background: #20bb4c;
        width: 30px;
        height: 30px;
        border-radius: 15px;
      }
      .gray-point {
        background: #9b9b9b;
        width: 30px;
        height: 30px;
        border-radius: 15px;
      }
      .green-line {
        background: #20bb4c;
        height: 2px;
      }

      .gray-line {
        background: #9b9b9b;
        height: 2px;
      }
    }
  }
  .step-ongoing {
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: space-between;
    .green-box {
      display: flex;
      flex-direction: row;
      align-items: center;
      .green-point {
        background: #20bb4c;
        width: 30px;
        height: 30px;
        border-radius: 15px;
      }
      .gray-point {
        background: #9b9b9b;
        width: 30px;
        height: 30px;
        border-radius: 15px;
      }
      .green-line {
        background: #20bb4c;
        height: 2px;
      }

      .gray-line {
        background: #9b9b9b;
        height: 2px;
      }
    }
  }
  .step-end {
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: space-between;
    .green-box {
      display: flex;
      flex-direction: row;
      align-items: center;

      .green-point {
        background: #20bb4c;
        width: 30px;
        height: 30px;
        border-radius: 15px;
      }
      .gray-point {
        background: #9b9b9b;
        width: 30px;
        height: 30px;
        border-radius: 15px;
      }
      .green-line {
        background: #20bb4c;
        height: 2px;
      }

      .gray-line {
        background: #9b9b9b;
        height: 2px;
      }
    }
  }
}
/deep/.el-select .el-input__inner {
  // height: 30px;
  // width: 80px;
}
.logger-detail {
  color: #409eff;
  cursor: pointer;
}

/* 添加样式 */
.device-list {
  display: flex;
  flex-wrap: wrap;
  .device-detail {
    flex: 1 0 50%; /* 每个设备占50%的宽度 */
    box-sizing: border-box;
    padding: 5px;
    cursor: pointer;
    color: #409eff;
    cursor: pointer;
  }

  .device-detail br {
    display: none; /* 去掉不必要的换行符 */
  }
  // 鼠标悬停样式
  // .device-detail:hover {
  //   background-color: #f0f0f0;
  // }
}

/deep/.el-tabs__item {
  font-size: 18px;
}
.cause-box {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  .source-box {
    display: flex;
    flex-direction: row;
  }
  .rule-box {
    display: flex;
    flex-direction: row;
  }
}
</style>
