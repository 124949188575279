<!--
 * @Author       : JiangChao
 * @Date         : 2024-11-05 15:05:29
 * @LastEditors  : JiangChao
 * @LastEditTime : 2024-11-07 13:31:40
 * @Description  : 更新运单的组织
-->
<template>
  <el-dialog
    :visible.sync="dialogFormVisible"
    width="55%"
    :append-to-body="true"
  >
    <template slot="title">
      <div class="font-heavy" style="font-size: 18px;color: #303133">
        {{ $t("update_shipment_org") }}
      </div>
    </template>
    <div class="form">
      <div class="right" v-if="showBtn">
        <el-button
          style="width: 145px; border: 1px solid #1a72f4; color: #1a72f4"
          plain
          @click="dialogFormVisible = false"
          >{{ $t("cancel") }}</el-button
        >
        <el-button
          style="width: 145px; background: #1a72f4; border: 1px solid #1a72f4"
          type="primary"
          @click="handelOk"
        >
          {{ $t("confirm") }}</el-button
        >
      </div>
    </div>
    <!-- 表格 -->
    <el-table
      :data="orgList"
      style="width: 100%"
      ref="orgTable"
      border
      :header-cell-style="{
        background: '#f6f6fa',
        textAlign: 'center',
        color: 'black',
      }"
      :cell-style="{ 'text-align': 'center' }"
      @selection-change="handleOrganSelectionChange"
      :empty-text="$t('no_data')"
    >
      <el-table-column type="selection" width="55"> </el-table-column>
      <el-table-column :label="$t('organ_id')" prop="orgId"> </el-table-column>
      <el-table-column :label="$t('organ_name')" prop="orgName">
      </el-table-column>
    </el-table>
  </el-dialog>
</template>

<script>
import { updateShipmentOrgApi } from "@/api/api.js";

export default {
  name: "UpdateShipmentOrgDialog",
  data() {
    return {
      dialogFormVisible: false,
      form: {},
      organSelection: [],
      isChina: true,
      orgList: [],
      userId: "",
      showBtn: false,
    };
  },
  methods: {
    showDialog(id, userId, arr) {
      this.shipmentId = id;
      this.showBtn = userId == localStorage.getItem("userId") ? true : false;
      console.log("this.showBtn: ", this.showBtn);
      this.orgList = arr;
      this.dialogFormVisible = true;
      this.$refs.orgTable.clearSelection();
    },

    handelOk() {
      if (this.organSelection.length <= 0) {
        this.$message.error(this.$t("each_time_add_one_organ"));
        return;
      }
      let params = {
        shipmentId: this.shipmentId,
        userId: this.userId,
        orgId: this.organSelection[0].orgId,
      };
      console.log("params: ", params);

      updateShipmentOrgApi(params).then((r) => {
        console.log("r: ", r);

        this.$message.success(this.$t("operation_successful"));
        this.dialogFormVisible = false;
        this.$emit("refresh");
      });
    },
    handleOrganSelectionChange(val) {
      console.log("val: ", val);
      this.organSelection = val;
    },
  },
  props: {},
  components: {},
  computed: {
    setSelectedTimeZone() {
      return this.$store.getters.selectedTimeZone;
    },
    setSelectedTempType() {
      return this.$store.getters.selectedTempType;
    },
    setSelectedLanguage() {
      return this.$store.getters.selectedLanguage;
    },
  },

  watch: {
    setSelectedLanguage(newVal) {
      this.$i18n.locale = newVal;
    },
  },

  mounted() {
    this.$i18n.locale = this.setSelectedLanguage;
    this.isChina = JSON.parse(localStorage.getItem("isChina"));
    this.userId == localStorage.getItem("userId");
    console.log("this.userId: ", this.userId);
  },
  created() {},
};
</script>

<style scoped lang="less">
.form {
  display: flex;
  justify-content: right;
  padding-bottom: 20px;
  .top {
    position: absolute;
    right: 0;
  }
  /deep/.el-checkbox .el-checkbox__inner {
    display: none;
  }
}
</style>
